import Vue from 'vue'
import Vuex from 'vuex'
import { Agente_auth as AAuth } from 'portalba360'
import account from './account'
import contact from './contact'
import caseStore from './case'
import typing from './typing'
import snackbar from './snackbar'
import note from './note'
import customFields from './customFields'
import group from './group'
import process from './process'
import parameter from './parameters'
import loader from './loader'
import checklist from './checklist'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userlogin: new AAuth(),
    data: {},
    params: {}
  },
  mutations: {
    setInstance (response) {
      this.state.data = response
    },
    setParams (state, params) {
      state.params = params
    }
  },
  actions: {
    async authToken ({ commit, state }, data) {
      return await state.userlogin.loginSE(data)
    },
    async auth ({ state }, data) {
      return await state.userlogin.login(data)
    },
    updatePathParams (context, params) {
      // TODO: Ask for what we need this information
      context.commit('setParams', params)
    }
  },
  getters: {
    getOriginPath: state => {
      return `/external/${state.params.platform}/${state.params.channel}/${state.params.interactionId}/${state.params.token}`
    },
    getChannel: state => {
      return state.params.channel
    },
    getPlatform: state => {
      return state.params.platform
    },
    getInteraction: state => {
      return state.params.interactionId
    },
    getLoggedUser: state => {
      return state.userlogin.one('usuario')
    }
  },
  modules: {
    account,
    contact,
    snackbar,
    note,
    caseStore,
    typing,
    customFields,
    group,
    process,
    parameter,
    loader,
    checklist
  }
})
