import { Agente_nota as ANote } from 'portalba360'
export default {
  namespaced: true,
  state: () => ({
    note: new ANote()
  }),
  actions: {
    async getAllNotes ({ state }, data) {
      return await state.note.all(data)
    },
    async addNote ({ state }, data) {
      return await state.note.add(data)
    }
  }
}
