import { Agente_proceso as AProcess } from 'portalba360'
export default {
  namespaced: true,
  state: () => ({
    process: new AProcess()
  }),
  actions: {
    async nextStep ({ state }, data) {
      return await state.process.next(data)
    },
    async backStep ({ state }, data) {
      return await state.process.back(data)
    }
  }
}
