<template>
  <v-card elevation="2" outlined class="ba-glass-card ba-card">
    <slot></slot>
    <v-form>
      <v-container>
        <v-row>
          <v-col cols="12" v-if="!hasCompany">
            <v-text-field
              class="ba-text"
              :label="$t('ba-library.login.form.company')"
              outlined
              v-model="formLogin.company"
              prepend-inner-icon="mdi-bank"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              class="ba-text"
              :label="$t('ba-library.login.form.user')"
              type="email"
              outlined
              v-model="formLogin.user"
              prepend-inner-icon="mdi-account-outline"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              class="ba-text"
              :label="$t('ba-library.login.form.password')"
              type="password"
              outlined
              v-model="formLogin.pass"
              prepend-inner-icon="mdi-lock-outline"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-btn block elevation="2" @click="login()" class="ba-form ba-btn">
              <v-icon small>mdi-login</v-icon>
              {{ $t("ba-library.login.action.login") }}
            </v-btn>
          </v-col>
          <p v-if="error_message" class="error-message">{{ $t("ba-library.login.error") }}</p>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: 'ba-login',
  props: {
    classes: Object
  },
  data () {
    return {
      hasCompany: !!process.env.VUE_APP_COMPANY_AGENTE,
      formLogin: {
        company: process.env.VUE_APP_COMPANY_AGENTE || '',
        user: '',
        pass: ''
      },
      error_message: false,
      error: true
    }
  },
  methods: {
    login () {
      this.$store.dispatch('auth', { ...this.formLogin, url: process.env.VUE_APP_API_URL_AGENTE })
        .then(() => {
          localStorage.setItem('company', this.formLogin.company)
          localStorage.setItem('user', this.formLogin.user)
          this.$router.push({ name: 'externalContactList' })
        })
        .catch(() => {
          this.error = true
          this.error_message = true
        })
    }
  }
}
</script>

<style>
.error-message {
  color: red;
  font-size: 75%;
}
</style>
