import { Agente_cf as ACustomFields } from 'portalba360'
export default {
  namespaced: true,
  state: () => ({
    customFiels: new ACustomFields()
  }),
  actions: {
    async getAllCustomFields ({ state }, data) {
      return await state.customFiels.all(data)
    }
  }
}
