<template>
  <div id="loading">
    <div class="loading-mask"></div>
  </div>
</template>

<script>
export default {
  name: 'ba-loader'
}
</script>

<style>
.loading-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  cursor: pointer;
}

.loading-mask::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  border-width: 3px;
  border-style: solid;
  border-color: transparent rgb(255, 255, 255) rgb(255, 255, 255);
  width: 72px;
  height: 72px;
  top: calc(50% - 36px);
  left: calc(50% - 36px);
  animation: 2s linear 0s normal none infinite running spin;
  filter: drop-shadow(0 0 2 rgba(0, 0, 0, 0.33));
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
