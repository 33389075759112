import { Agente_tipificacion as ATyping, Agente_estado as AState } from 'portalba360'
export default {
  namespaced: true,
  state: () => ({
    typing: new ATyping(),
    agentState: new AState()
  }),
  actions: {
    async getProducts ({ state }, data) {
      return await state.typing.productosAll(data)
    },
    async getTypes ({ state }, data) {
      return await state.typing.motivosAll(data)
    },
    async getSubtypes ({ state }, data) {
      return await state.typing.subMotivosAll(data)
    },
    async getStates ({ state }, data) {
      return await state.agentState.all(data)
    }
  }
}
