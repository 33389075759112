export default {
  namespaced: true,
  state: () => ({
    show: false,
    msg: '',
    type: '',
    color: ''
  }),
  mutations: {
    alert (state, payload) {
      state.type = payload.type

      if (state.type === 'success') {
        state.color = 'rgba(46,141,50,0.7)'
      }

      if (state.type === 'warning') {
        state.color = 'rgba(185,129,22,0.7)'
      }

      if (state.type === 'error') {
        state.color = 'rgba(208,40,40,0.7)'
      }

      state.msg = payload.msg
      state.show = true
    },
    closeAlert (state) {
      state.show = false
    }
  },
  actions: {
    alert ({ commit }, config) {
      commit('alert', config)
    },
    close ({ commit }) {
      commit('closeAlert')
    }
  }
}
