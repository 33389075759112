<template>
  <div>
    <ba-loader v-show="$store.state.loader.loading" />
    <router-view />
  </div>
</template>

<script>
import BaLodader from './components/Loader.vue'
export default {
  name: 'App',
  components: {
    'ba-loader': BaLodader
  },
  data: () => ({
    //
  })
}
</script>
