import { Agente_caso as ACase, Agente_user as AUser } from 'portalba360'
export default {
  namespaced: true,
  state: () => ({
    case: new ACase(),
    user: new AUser()
  }),
  actions: {
    async getAllCases ({ state }, data) {
      return await state.case.all(data)
    },
    async getCase ({ state }, data) {
      return await state.case.one(data)
    },
    async addCase ({ state }, data) {
      return await state.case.add(data)
    },
    async updateCase ({ state }, data) {
      return await state.case.upd(data)
    },
    async getUserAssigned ({ state }, data) {
      return await state.user.one(data)
    },
    async getUsers ({ state }, data) {
      return await state.user.getdisponibles(data)
    }
  }
}
