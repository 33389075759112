import { Agente_cuenta as AAccount } from 'portalba360'
export default {
  namespaced: true,
  state: () => ({
    account: new AAccount()
  }),
  actions: {
    async getAllCuentas ({ state }, data) {
      return await state.account.all(data)
    }
  }
}
