<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="../../assets/images/img.png"
          transition="scale-transition"
          width="130"
        />
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <router-view :key="$route.fullPath" />
      <ba-snack />
    </v-main>
  </v-app>
</template>

<script>
import BaSnackbar from '@/components/Snackbar.vue'

export default {
  name: 'Home',
  components: {
    'ba-snack': BaSnackbar
  }
}
</script>
