import { Agente_contacto as AContact } from 'portalba360'
export default {
  namespaced: true,
  state: () => ({
    contact: new AContact()
  }),
  actions: {
    async getAllContacts ({ state }, data) {
      return await state.contact.all(data)
    },
    async getContact ({ state }, data) {
      return await state.contact.one(data)
    },
    async addContact ({ state }, data) {
      return await state.contact.add(data)
    },
    async updateContact ({ state }, data) {
      return await state.contact.upd(data)
    }

  }
}
