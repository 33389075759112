import { Agente_parametro as AParameter } from 'portalba360'
export default {
  namespaced: true,
  state: () => ({
    parameter: new AParameter(),
    slaParameters: [],
    slaColors: [
      { name: 'AMARILLO', value: '#fb8c00' },
      { name: 'ROJO', value: '#ff5252' },
      { name: 'VERDE', value: '#4caf50' },
      { name: 'NEGRO', value: '#000' }
    ]
  }),
  mutations: {
    setSlaParams (state, parameters) {
      state.slaParameters = parameters
    }
  },
  getters: {
    getSlaParameters: state => {
      return state.slaParameters
    }
  },
  actions: {
    async getAllParameters ({ commit, state }, data) {
      try {
        const parameters = await state.parameter.systemAll(data)
        const slaColors = state.slaColors
        const result = parameters.map(parameter => {
          const color = slaColors.find(color => color.name === parameter.key)
          return {
            key: parameter.key,
            value: parseInt(parameter.value),
            color: color ? color.value : undefined
          }
        }).filter(param => param.color)
        commit('setSlaParams', result)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
