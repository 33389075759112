import { Agente_check as ACheck } from 'portalba360'
export default {
  namespaced: true,
  state: () => ({
    check: new ACheck()
  }),
  actions: {
    async getAllCheck ({ state }, data) {
      return await state.check.all(data)
    },
    async check ({ state }, data) {
      return await state.check.check(data)
    }
  }
}
