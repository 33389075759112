import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/Home.vue'
import Login from '../views/Login'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    name: 'external',
    component: Home,
    children: [
      {
        path: 'contact',
        name: 'externalContact',
        component: () => import('../views/Home/Contact')
      },
      {
        path: 'contact-list',
        name: 'externalContactList',
        component: () => import('../views/Home/Contact/ContactList.vue')
      },
      {
        path: 'case',
        name: 'case',
        component: () => import('../views/Home/Cases')
      },
      {
        path: 'case-list',
        name: 'caseList',
        component: () => import('../views/Home/Cases/CaseList')
      },
      {
        path: 'external/:platform/:channel/:interactionId/:token',
        name: 'configure',
        component: () => import('../views/Home/Configuration.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
