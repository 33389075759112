import { Agente_grupo as AGroup } from 'portalba360'
export default {
  namespaced: true,
  state: () => ({
    group: new AGroup()
  }),
  actions: {
    async getGroups ({ state }) {
      return await state.group.all({
        tipo: 2
      })
    }
  }
}
