<template>
  <v-app>
    <div class="bg">
    <v-container class="ba-container">
      <ba-login>
        <div class="logo">
          <img src="@/assets/images/logo-login.png">
        </div>
      </ba-login>
    </v-container>
    </div>
  </v-app>
</template>

<script>
import FormLogin from './FormLogin.vue'
export default {
  components: {
    'ba-login': FormLogin
  }
}
</script>

<style>
@media (min-width: 380px) {
  .container.ba-container {
    max-width: 380px;
  }
}
.logo {
  display: flex;
  justify-content: center;
}
.bg {
  min-height: 100vh;
  background: linear-gradient(90deg, rgba(2,73,85,1) 0%, rgba(19,123,140,1) 35%, rgba(7,170,197,1) 100%);
  padding-top: 20vh;
}
.theme--light.v-card.ba-glass-card.ba-card {
  padding: 30px;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
}
.theme--light.v-btn.v-btn--has-bg.ba-form {
  background-color: #037A8D;
  color: white;
}
.v-text-field.v-text-field--enclosed.ba-text {
  margin-bottom: -40px;
}
</style>
