<template>
  <v-snackbar
    v-model="snackbar.show"
    multi-line
    centered
    rounded='pill'
    :elevation="4"
    :color="snackbar.color"
    :timeout="2000"
    :top="true"
    :right="true"
  >
    <b>{{ snackbar.msg }}</b>

    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="$store.dispatch('snackbar/close')"
      >
        CERRAR
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'ba-snackbar',
  computed: {
    snackbar () {
      return this.$store.state.snackbar
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
